import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import search1 from 'img/help/search/search1.png';
import search2 from 'img/help/search/search2.png';
import search3 from 'img/help/search/search3.png';
import search4 from 'img/help/search/search4.png';
import search5 from 'img/help/search/search5.png';
import search6 from 'img/help/search/search6.png';

const Content = () => {
  return (
    <div>
      <SubTitle>Search - what is it?</SubTitle>
      <p>
        The Search helps you to find sessions under conditions that you can specify yourself. For
        example you can limit session results to mobile users from one country.
      </p>
      <SubTitle>What does the Search look like?</SubTitle>

      <p>
        Search input is on top of the page and also is available through most of our application
        pages (in few is hidden) and it shows results based on such page. For example if you search
        on the dashboard then you can see dashboard tiles filtered by your terms.
      </p>
      <Image src={search1} lazy alt="LiveSession App - Search" title="LiveSession Search" />

      <p>After clicking, we can choose from robust predefined filters grouped by use case.</p>
      <Image
        imgStyle={{
          width: '100%',
        }}
        src={search2}
        lazy
        alt="LiveSession App - Search - Filters"
        title="LiveSession Search filters"
      />

      <p>If you type on Search input, we&quot;ll match with available filters with that name.</p>
      <Image
        src={search3}
        lazy
        alt="LiveSession App - Search - Filters - Typing"
        title="LiveSession typing Search filters"
      />

      <p>
        As you can see, you can use multiple terms in one query. In that scenario all terms have to
        be applied to find results.
      </p>
      <Image
        src={search4}
        lazy
        alt="LiveSession App - Search - Term view"
        title="LiveSession Search terms"
      />

      <p>
        As we mentioned before, Search works based on context where it runs. We also applied two
        filters.
      </p>
      <Image
        src={search5}
        lazy
        alt="LiveSession App - Search - Dashboard - Terms"
        title="LiveSession Search dashboard terms"
      />

      <p>If you do the same query but on Session Page then you can find matched up sessions.</p>
      <Image
        src={search6}
        lazy
        alt="LiveSession App - Search - Sessions - Terms"
        title="LiveSession Search sessions terms"
      />
    </div>
  );
};

const related = [
  {
    link: '/help/what-is-a-session/',
    title: 'What is a session?',
    description: 'A session is a set of the user’s interactions with a website within a timeframe.',
  },
  {
    link: '/help/how-does-the-timeline-work/',
    title: 'How does the Timeline work?',
    description: 'See how Timeline does looks like and how it works.',
  },
];

export const frontmatter = {
  metaTitle: 'How does the Search work?',
  metaDescription: 'Wondering about the usability of our Search? Read here.',
  canonical: '/help/how-does-the-search-work/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
